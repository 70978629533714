<div class="profile-info container-dialog-set pt-16" [formGroup]="form">
  <div class="gap-24 align-center">
    <div>
      <app-image-view [width]="'80px'" [height]="'80px'"
                      [rounded]="true"
                      [image]="userInfo?.avatarUrl"
                      [placeholderImage]="'user-grey'"
      />
    </div>
    <div>
      <div class="font-14px fw-400 tc-dust mb-5">
        SVG, PNG, JPG (max. 300x300px)
      </div>
      <div>
        <app-button-file [label]="'Upload Photo'"
                         [class]="'btn btn-o-primary'"
                         [icon]="icon.plusRed.src"
                         (onChange)="uploadPhoto($event)"
        />
      </div>
    </div>
  </div>
  
  <hr class="mt-24 mb-14">

  <div class="d-grid gap-8">
    <label class="text-label">Phone Number</label>
    <account-contact-info 
      [contact]="userInfo?.contactPhone" 
      [status]="userInfo?.contactPhoneVerified" 
    />
  </div>
  
  <hr class="mt-24 mb-14">

  <div class="d-grid gap-8">
    <label class="text-label">Email</label>
    <account-contact-info
      [contact]="userInfo?.contactEmail"
      [status]="userInfo?.contactEmailVerified"
    />
  </div>

  <hr class="mt-24 mb-14">
  
  <div class="form-set">
    <!-- LANGUAGE -->
    <div>
      <label class="text-label">Language</label>
      <div class="mt-6">
        <app-checkbox-card *ngFor="let language of sampleLanguages"
                           [id]="language.id"
                           [name]="'language'"
                           [label]="language.name"
                           [checked]="language.id === userInfo?.language"
        />
      </div>
    </div>

    <hr class="mt-24 mb-14">
    <!-- SIGNATURE -->
    <div>
      <div class="justify-between mb-16">
        <label class="text-label">Signature</label>
        <button-manage 
          [icon]="'add'" 
          [label]="'Add Signature'" 
          (onClick)="updateSignature()" 
        />
      </div>
      
      <div class="mt-6">        
       <account-signature />
      </div>
    </div>
  </div>
</div>

<div class="footer-container">
  <span class="text-btn-underline" (click)="cancel()">
    Cancel
  </span>
  <app-roam-button [label]="'Save'"
                   [class]="'btn-primary'"
  />
</div>
