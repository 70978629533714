<div *ngIf="userInfo; else loading">
  <div class="d-flex gap-10 c-pointer">
    <div class="chip" *ngFor="let menu of profileTabMenus"
         [class.active]="selectedMenu === menu"
         (click)="selectedMenu = menu">
      {{ menu }}
    </div>
  </div>
  <hr class="mb-0">
  <div>
    <ng-container [ngSwitch]="selectedMenu">

      <!-- ADDRESS -->
      <ng-container *ngSwitchCase="profileTabMenus[1]">
        <ng-container *ngTemplateOutlet="address"></ng-container>
      </ng-container>

      <!-- DEFAULT -->
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="profile"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #profile>
  <app-account-profile-information 
    [userInfo]="userInfo" 
    (onCancel)="onCancel.emit()" 
  />
</ng-template>

<ng-template #address>
  <app-account-profile-address></app-account-profile-address>
</ng-template>

<ng-template #loading>
  <app-loader-dot></app-loader-dot>
</ng-template>
