import { Component, EventEmitter, Input, OnChanges, OnInit, Output, inject } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { FormBuilder, FormGroup } from '@angular/forms'
import { UserService } from "@app/shared/services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { SignatureRequestsSignSignatureComponent } from "@app/pages/communication/components/signature-requests-sign-signature";
import { dialogConfig } from "@app/core/const/dialog.const";

@Component({
  selector: 'app-account-profile-information',
  templateUrl: './account-profile-information.component.html',
  styleUrls: ['./account-profile-information.component.scss']
})
export class AccountProfileInformationComponent implements OnChanges {
  #dialog = inject(MatDialog);
  formBuilder = inject(FormBuilder);
  userService = inject(UserService);

  @Input() userInfo: any;

  @Output()
  public onCancel: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public icon = iconLib;
  public sampleSignature = './assets/sample/signature.png';
  public sampleAvatar = '/assets/sample/avatar.png';
  public form: FormGroup = this.formBuilder.group({
    name: [''],
    phone: [''],
    contactEmail: [''],
    reservationUpdatesEmail: [false],
    reservationUpdatesSms: [false],
  });

  public sampleLanguages = [{ id: 'en', name: 'English' }, { id: 'es', name: 'Spanish' }];
  readonly defaultLanguage = 'en';
  selectedFile: File | null = null;


  ngOnChanges(): void {
    this.form.patchValue({
      name: this.userInfo?.name ?? '',
      phone: this.userInfo?.phones?.[0]?.number ?? '',
      contactEmail: this.userInfo?.contactEmail ?? '',
      reservationUpdatesEmail: this.userInfo?.reservationUpdatesEmail ?? false,
      reservationUpdatesSms: this.userInfo?.reservationUpdatesSms ?? false,
    })
  }

  public setLanguage(lgs: string): void {
    // TODO: update language
  }

  public uploadPhoto(event: Event): void {
    const file = (event.target as HTMLInputElement);
    if (file.files && file.files.length > 0) {
      this.selectedFile = file.files[0];
      this.#uploadFile();
    }
  }

  #uploadFile(): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('image', this.selectedFile);
      this.userService.updateUserAvatar(formData).subscribe({
        next: (resp) => {
          this.userInfo.avatarUrl = resp.user.avatarUrl;
        },
        error: () => {
        }
      });
    }
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  updateSignature(): void {
    this.#dialog.open(SignatureRequestsSignSignatureComponent, {
      ...dialogConfig.addDialogMd
    });
  }
}

