import { Component, input, output } from "@angular/core";
import { ButtonManageComponent } from "@app/shared/components/button/button-manage/button-manage.component";
import { VerifiedStatusComponent } from "@app/shared/components/label-status/verified-status/verified-status.component";

@Component({
  selector: "account-contact-info",
  standalone: true,
  imports: [ButtonManageComponent, VerifiedStatusComponent],
  template: ` 
    <div class="justify-between">
      <h6 [class.tc-grey]="!contact()" [class.font-12px]="!contact()">
        {{ contact() || 'N/A' }}
      </h6>
      <button-manage 
        [icon]="status() ? 'pencil-box' : 'check'" 
        [color]="'primary'"
        [label]="status() ? 'Update' : 'Verify Now'" 
        (click)="onUpdate.emit()"
      />
    </div>
    <verified-status [status]="status() ? 'verified' : 'unverified'" />
  `,
  styles: `
    :host {
      padding: 1rem;
      background-color: #f9f9f9;
      border-radius: 8px;
      display: grid;
      gap: 10px;
    }
  `,
})
export class AccountContactInfoComponent {
  contact = input<string>('');
  status = input<boolean>(false);
  onUpdate = output();
}
